import {FC, useState} from 'react';
import styles from './menuItem.module.css';
import { UserProfile } from '../../../../shared/types';
import {ProfileCard} from "../profile/ProfileCard";
import classNames from "classnames";

interface UserTopMenuItemProps extends UserProfile {
    signout:()=>void;
}
const UserTopMenuItem: FC<UserTopMenuItemProps> = (props) => {
    const [isOpen,setIsOpen]=useState<boolean>(false)
    const profileCardStyle=classNames({
        ["transition-opacity duration-200"]:true,
        ["show"]: isOpen,
        ["notDisplayV2"]:!isOpen
    })
    return (
      <div className="relative">
          <div className={styles.userMenuItem} onClick={()=>setIsOpen(!isOpen)}>
              <img className={styles.userMenuItemImage}
                   src={props.avatarUrl ? '/pictures/example-air-royale.png' : props.avatarUrl} alt=""/>
              <span>{props.fullName}</span>
          </div>
          <ProfileCard className={profileCardStyle} imageUrl={"/pictures/example-air-royale.png"} name={props.fullName} email={props.email} signout={props.signout}/>
      </div>
  );
};
export default UserTopMenuItem;
