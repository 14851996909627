import styles from './forgetpassword-steps.module.css';
import SendMessageIcon from '../../../../shared/assets/icons/send-message.svg';
import classNames from 'classnames';
import { InputPin, NotificationCard, SimpleCard } from '../../../../shared/components/ui';
import { FC, useEffect } from 'react';
import { useHandleTranslation, useTimer } from '../../../../shared/hooks';
import { message } from 'antd';
import { NotificationType } from '../../../../shared/utils/constants';
import { useNavigate } from 'react-router-dom';

interface ForgotPasswordRequestApiStatus {
    requestPasswordReset: boolean | undefined;
    resendActivationCode: boolean | undefined;
}

interface ForgotPasswordRequestProps {
    onSubmit: (email: string) => void;
    isLoading?: ForgotPasswordRequestApiStatus;
    isError?: ForgotPasswordRequestApiStatus;
    isSuccess?: ForgotPasswordRequestApiStatus;
    deadline?: string;
    submitError?: string | undefined;
    resendActivationCode?: () => void;
    className?: string;
}

const ForgotPasswordRequest: FC<ForgotPasswordRequestProps> = (props) => {
    const {
        className = '',
        submitError = '',
        deadline,
        isError = { requestPasswordReset: false, resendActivationCode: false },
        isLoading = { requestPasswordReset: false, resendActivationCode: false },
        isSuccess = { requestPasswordReset: false, resendActivationCode: false }
    } = props;

    const { t: tS } = useHandleTranslation('resetPassword');
    const { isRunning: isTimerRunning, minutes, seconds } = useTimer(deadline);
    const navigate = useNavigate();

    const containerStyles = classNames({
        [className]: true,
        [styles.requestRootContainer]: true
    });

    const submitData = (value: string) => {
        props.onSubmit(value);
    };

    const errorMessageStyles = classNames({
        ['notShow']:
        !props.isError?.requestPasswordReset ||
        props.isLoading?.requestPasswordReset ||
        !isTimerRunning,
        ['show transition-all duration-500']:
        props.isError?.requestPasswordReset && isTimerRunning
    });

    const [messageApi, contextHolder] = message.useMessage();
    const alertKey = 'forgot-password-request';

    useEffect(() => {
        if (isLoading.resendActivationCode) {
            messageApi.loading({
                key: alertKey,
                content: tS('forgot_password_request_resend_activation_code_loading'),
                duration: 0
            });
        }
    }, [isLoading.resendActivationCode]);

    useEffect(() => {
        if (isError.resendActivationCode) {
            messageApi.error({
                key: alertKey,
                content: tS('forgot_password_request_resend_activation_code_error'),
                duration: 2.5
            });
        }
    }, [isError.resendActivationCode]);

    useEffect(() => {
        if (isSuccess.resendActivationCode) {
            messageApi.success({
                key: alertKey,
                content: tS('forgot_password_request_resend_activation_code_success'),
                duration: 2.5
            });
        }
    }, [isSuccess.resendActivationCode]);

    useEffect(() => {
        if (isSuccess.requestPasswordReset) {
            messageApi
                .success({
                    key: alertKey,
                    content: tS('forgot_password_request_success'),
                    duration: 2.5
                })
                .then(() => {
                    navigate("/reset-password");
                });
        }
    }, [isSuccess.requestPasswordReset]);

    const expiredTimerStyles = classNames({
        ['grid grid-cols-2 gap-3']: true,
        ['show']: isTimerRunning,
        ['notDisplayV2']: !isTimerRunning
    });

    const expiredAlertStyles = classNames({
        [`show ${styles.requestExpiredAlertContainer}`]: !isTimerRunning,
        ['notDisplayV2']: isTimerRunning
    });

    return (
        <SimpleCard className={containerStyles} spin={isLoading.requestPasswordReset}>
            {contextHolder}
            <div className="flex flex-col gap-8 justify-center align-center text-center">
                <div className={'flex justify-center'}>
                    <img src={SendMessageIcon} alt="logo" />
                </div>
                <div className={'flex flex-col justify-center gap-4'}>
                    <p className={styles.requestTitle}>{tS('forgot_password_request_title')}</p>
                    <p className={styles.requestDescription}>
                        {tS('forgot_password_request_description')}
                    </p>
                </div>
                <div className="w-3/4 m-auto">
                    <InputPin
                        onSubmit={submitData}
                        empty={isError.requestPasswordReset}
                        disabled={!isTimerRunning}
                        upperCase
                    />
                </div>
                <div className="flex flex-col gap-3">
                    <NotificationCard
                        type={NotificationType.ERROR}
                        className={errorMessageStyles}>
                        {tS(submitError)}
                    </NotificationCard>
                    <div className="flex justify-center items-center min-h-9">
                        <NotificationCard
                            type={NotificationType.WARNING}
                            className={expiredAlertStyles}>
                            <div className={'flex gap-2'}>
                                <span className={styles.requestExpiredAlertLabel}>
                                    {tS('forgot_password_request_expired_code_message')}
                                </span>
                                <span
                                    onClick={props.resendActivationCode}
                                    className={styles.requestResendActivationLabel}>
                                    {tS('forgot_password_request_resend_activation_code_link')}
                                </span>
                            </div>
                        </NotificationCard>
                        <div className={expiredTimerStyles}>
                            <span className={styles.requestExpiredTimerLabel}>
                                {tS('forgot_password_request_timer_label')}
                            </span>
                            <span className={styles.requestTimer}>
                                {`${minutes} ${tS('minute_unit_label')} ${seconds} ${tS('second_unit_label')}`}
                            </span>
                        </div>
                    </div>
                    <p className={styles.requestSpam}>
                        {tS('forgot_password_request_spam_message')}
                    </p>
                </div>
            </div>
        </SimpleCard>
    );
};

export default ForgotPasswordRequest;
