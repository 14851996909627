import { Dropdown } from 'kls-ui';
import { FC } from 'react';

export interface FilterBarItemSortByProps {
  label: string;
  data: Array<{
    [key: string]: string | undefined;
  }>;
  placeHolder: string;
  selectedValue: string;
}

export interface FilterBarSortByProps {
  items: FilterBarItemSortByProps[];
}

export const FilterBarSortBy: FC<FilterBarSortByProps> = (props) => {
  return (
    <div className="flex items-center gap-3">
      {props.items.map((item: FilterBarItemSortByProps, index: number) => (
        <Dropdown
          withoutError
          label={item.label}
          key={index}
          size={'md'}
          elements={item.data}
          elementSize={32}
          args={{
            value: item.selectedValue,
            placeholder: item.placeHolder
          }}></Dropdown>
      ))}
    </div>
  );
};
