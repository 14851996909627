import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const AcademySvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M7.50057 0L0.167236 4L2.8339 5.45333V9.45333L7.50057 12L12.1672 9.45333V5.45333L13.5006 4.72667V9.33333H14.8339V4L7.50057 0ZM12.0472 4L7.50057 6.48L2.9539 4L7.50057 1.52L12.0472 4ZM10.8339 8.66L7.50057 10.48L4.16724 8.66V6.18L7.50057 8L10.8339 6.18V8.66Z" />
  </AbstractSVGIcon>
);
export default AcademySvg;
