import { Input } from 'kls-ui';
import { ChangeEvent, FC } from 'react';
import SearchSvg from '../../../../shared/components/icons/SearchSvg';
import XSvg from '../../../../shared/components/icons/XSvg';
import VerticalDeviderSvg from '../../../../shared/components/icons/VerticalDeviderSvg';
import {
  FilterBarButton,
  FilterBarButtonProps
} from '../ui/buttons/FilterBarButton';
import { IconSize } from '../../../../shared/utils/enums';
import styles from './filterBar.module.css';
import {
  FilterBarSortByTime,
  FilterBarSortByTimeProps
} from './FilterBarSortByTime';

export interface FilterBarInputProps {
  filterBarInputValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  filterBarInputPlaceHolder: string;
  filterBarInputType: string;
  filterBarInputId: string;
  onSearch: (value: string) => void;
  onClear: () => void;
  isGridDisplayMode: boolean;
  itemsFilterBarSort: FilterBarSortByTimeProps;
  filterButton: FilterBarButtonProps;
  mapButton: FilterBarButtonProps;
  gridButton: FilterBarButtonProps;
  listButton: FilterBarButtonProps;
}
export const FilterBarInput: FC<FilterBarInputProps> = (props) => {
  return (
    <div className={styles.filterInput}>
      <div className={styles.filterInputRelative}>
        <Input
          withoutError
          args={{
            value: props.filterBarInputValue,
            id: props.filterBarInputId,
            type: props.filterBarInputType,
            placeholder: props.filterBarInputPlaceHolder,
            onChange: (e) => props.onChange(e)
          }}
          size="md"
        />
        <div className={styles.filterInputAbsolute}>
          <span onClick={() => props.onClear()}>
            <XSvg className={styles.filterInputIcon} size={IconSize.PX12} />
          </span>
          <span onClick={() => props.onSearch(props.filterBarInputValue)}>
            <SearchSvg
              className={styles.filterInputIcon}
              size={IconSize.PX12}
            />
          </span>
        </div>
      </div>
      <div className={styles.filterInputFilterCont}>
        <FilterBarSortByTime
          label={props.itemsFilterBarSort.label}
          options={props.itemsFilterBarSort.options}
          selectedValue={props.itemsFilterBarSort.selectedValue}
        />
        <div className={styles.filterInputBtnsCont}>
          <FilterBarButton
            selected
            icon={props.filterButton.icon}
            label={props.filterButton.label}
            handleClick={props.filterButton.handleClick}
          />
          <VerticalDeviderSvg
            size={IconSize.MD}
            className={styles.filterInputDevider}
          />
          <FilterBarButton
            icon={props.mapButton.icon}
            handleClick={props.mapButton.handleClick}
          />
          <FilterBarButton
            selected
            icon={props.gridButton.icon}
            handleClick={props.gridButton.handleClick}
          />
          <FilterBarButton
            icon={props.listButton.icon}
            handleClick={props.listButton.handleClick}
          />
        </div>
      </div>
    </div>
  );
};
