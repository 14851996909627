import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const DeveloperZoneSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M6.26683 11.0667L3.20016 8L6.26683 4.93333L5.3335 4L1.3335 8L5.3335 12L6.26683 11.0667ZM9.7335 11.0667L12.8002 8L9.7335 4.93333L10.6668 4L14.6668 8L10.6668 12L9.7335 11.0667Z" />
  </AbstractSVGIcon>
);
export default DeveloperZoneSvg;
