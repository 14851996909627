import { FC } from 'react';
import { BaseSVGIconProps} from '../../../../shared/types/components.type';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './menuItem.module.css';
import { IconSize } from '../../../../shared/utils/enums';
export interface TopMenuItemProps {
  selectedItem: string;
  handleClick: (item: string) => void;
  label: string;
  link: string;
  icon: FC<BaseSVGIconProps>;
}

const SidebarMenuItemStyle = (props: TopMenuItemProps) =>
  classNames({
    [styles.topMenuItem]: true,
    [styles.innerBottomBorder]: props.label === props.selectedItem,
    [styles.topMenuItemColor]: props.label !== props.selectedItem
  });
const TopMenuItem: FC<TopMenuItemProps> = (props) => {
  return (
    <Link
      onClick={() => props.handleClick(props.label)}
      className={SidebarMenuItemStyle(props)}
      to={props.link}
    >
      <props.icon size={IconSize.SM}></props.icon>
      {props.label}
    </Link>
  );
};

export default TopMenuItem;
