import { FC } from 'react';
import styles from './card.module.css';
import FavoriteSvg from '../../../../shared/components/icons/FavoriteSvg';
import { IconSize } from '../../../../shared/utils/enums';
import VerifiedSvg from '../../../../shared/components/icons/VerifiedSvg';
import classNames from 'classnames';
import ThreePointsSvg from '../../../../shared/components/icons/ThreepointsSvg';
import OrdinateurSvg from '../../../../shared/components/icons/OrdinateurSvg';
export interface CardProps {
  name: string;
  industry: string;
  role?: string;
  status?: string;
  verification?: string;
  favorite?: boolean;
  imageUrl: string;
}
export const Card: FC<CardProps> = (props) => {
  const cardContainerStyle = classNames({
    [styles.cardContainer]: true,
    [styles['card-css-var']]: true
  });
  return (
    <div className={cardContainerStyle}>
      <div className={styles.card}>
        <div className={styles.starFContainer}>
          {props.favorite ? (
            <FavoriteSvg className={styles.starF} size={IconSize.PX18} />
          ) : (
            <FavoriteSvg className={styles.starNF} size={IconSize.PX18} />
          )}
          <div className={styles.threePointsContainer}>
            <ThreePointsSvg className={styles.threePoints} size={IconSize.SM} />
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={props.imageUrl} />
        </div>
        <div className={styles.industryContainer}>
          <span className={styles.industry}><OrdinateurSvg size={IconSize.PX14}/></span>
        </div>
      </div>
      <div className={styles.cardInfoContainer}>
        <span className={styles.cardTitle}>
          {props.name}
          {props.verification && (
            <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
          )}
        </span>
        {props.role && <span className={styles.cardRole}>{props.role}</span>}
      </div>
    </div>
  );
};
