import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const OffreSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M5.33105 12C4.59772 12 4.00439 12.6 4.00439 13.3334C4.00439 14.0667 4.59772 14.6667 5.33105 14.6667C6.06439 14.6667 6.66439 14.0667 6.66439 13.3334C6.66439 12.6 6.06439 12 5.33105 12ZM11.9977 12C11.2644 12 10.6711 12.6 10.6711 13.3334C10.6711 14.0667 11.2644 14.6667 11.9977 14.6667C12.7311 14.6667 13.3311 14.0667 13.3311 13.3334C13.3311 12.6 12.7311 12 11.9977 12ZM11.0311 8.66671C11.5311 8.66671 11.9711 8.39337 12.1977 7.98004L14.5844 3.65337C14.8311 3.21337 14.5111 2.66671 14.0044 2.66671H4.13772L3.51105 1.33337H1.33105V2.66671H2.66439L5.06439 7.72671L4.16439 9.35337C3.67772 10.2467 4.31772 11.3334 5.33105 11.3334H13.3311V10H5.33105L6.06439 8.66671H11.0311ZM4.77105 4.00004H12.8711L11.0311 7.33337H6.35105L4.77105 4.00004Z" />
  </AbstractSVGIcon>
);
export default OffreSvg;
