import { FC } from 'react';
import { BaseSVGIconProps } from '../../../../shared/types';
import styles from './filterBar.module.css';
import ArrowSvg from '../../../../shared/components/icons/ArrowSvg';
interface ButtonFilterProps {
  label: string;
  icon: FC<BaseSVGIconProps>;
}

export interface FilterBarContentProps {
  label: string;
  searchLength: number;
  buttons: ButtonFilterProps[];
  dropDownPlaceHolder: string;
  dropDownData: Array<{
    [key: string]: string | undefined;
  }>;
}

export const FilterBarContent: FC<FilterBarContentProps> = (props) => {
  return (
    <div className={styles.filterContent}>
      <div className="flex gap-4">
        <div className="flex gap-2 items-center">
          <span >{props.label}</span>
          <span className={styles.filterContentLength}>{props.searchLength}</span>
        </div>
        <div className="flex gap-4 justify-center">
          {props.buttons.map((button, index) => (
            <button className={styles.filterContentFltrBtn} key={index}>
              <button.icon/>
              {button.label}
            </button>
          ))}
        </div>
      </div>
      <div className="flex items-center">
        {/* <Dropdown
          withoutError
          size={'md'}
          elements={props.dropDownData}
          elementSize={32}
          args={{
            className:'',
            value: '',
            placeholder: props.dropDownPlaceHolder
          }}></Dropdown> */}
        <button className={styles.filterContentFltrBtnGuichet}>{props.dropDownPlaceHolder}<ArrowSvg/></button>
      </div>
    </div>
  );
};
