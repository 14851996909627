import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { ControlledUiProps } from '../../types';
import {Dropdown, DropdownProps} from "kls-ui";
export interface ControlledDropdownProps <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
    extends DropdownProps, ControlledUiProps<TFieldValues,TName>{}

export const ControlledDropdown = <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
>
(props:ControlledDropdownProps<TFieldValues, TName>) => {
    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange, onBlur,value,ref } }) => (
                <Dropdown
                    ref={ref}
                    {...props}
                    args={{
                        ...props.args,
                        onChange: onChange,
                        onBlur: onBlur
                    }}
                    value={value}
                    errorMessage={props.error}                          />
            )}
        />
    );
};

