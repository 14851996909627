import { FC } from 'react';
import ArrowSvg from '../../../../shared/components/icons/ArrowSvg';
import { IconSize } from '../../../../shared/utils/enums';
import styles from './filterBar.module.css';
export interface FilterBarSortByTimeProps {
  label: string;
  options: Array<{
    [key: string]: string | undefined;
  }>;
  selectedValue: string;
}
export const FilterBarSortByTime: FC<FilterBarSortByTimeProps> = (props) => {
  return (
    <div className={styles.filterSortByT }>
      <span>{props.label}</span>
      <div className={styles.filterSortByIcon }>
        {props.selectedValue}
        <ArrowSvg size={IconSize.PX12}/>
      </div>
    </div>
  );
};
