import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const ApplicationsSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M2.66699 5.33329H5.33366V2.66663H2.66699V5.33329ZM6.66699 13.3333H9.33366V10.6666H6.66699V13.3333ZM2.66699 13.3333H5.33366V10.6666H2.66699V13.3333ZM2.66699 9.33329H5.33366V6.66663H2.66699V9.33329ZM6.66699 9.33329H9.33366V6.66663H6.66699V9.33329ZM10.667 2.66663V5.33329H13.3337V2.66663H10.667ZM6.66699 5.33329H9.33366V2.66663H6.66699V5.33329ZM10.667 9.33329H13.3337V6.66663H10.667V9.33329ZM10.667 13.3333H13.3337V10.6666H10.667V13.3333Z" />
  </AbstractSVGIcon>
);
export default ApplicationsSvg;
