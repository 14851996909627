import { FC } from 'react';
import { FilterBarContent, FilterBarContentProps } from './FilterBarContent';
import { FilterBarHeader, FilterBarHeaderProps } from './FilterBarHeader';
import { FilterBarInput, FilterBarInputProps } from './FilterBarInput';
import { FilterBarSortBy, FilterBarSortByProps } from './FilterBarSortBy';

export interface FilterBarProps {
  filterBarHeader: FilterBarHeaderProps;
  filterBarContent: FilterBarContentProps;
  filterBarInput: FilterBarInputProps;
  filterBarSortBy: FilterBarSortByProps;
}
export const FilterBar: FC<FilterBarProps> = (props) => {
  return (
    <div className='flex flex-col gap-4'>
      <FilterBarHeader
        childs={props.filterBarHeader?.childs}
        hasChilds={props.filterBarHeader.hasChilds}
        parent={props.filterBarHeader.parent}
        icon={props.filterBarHeader.icon}
      />
      <FilterBarContent
        label={props.filterBarContent.label}
        searchLength={props.filterBarContent.searchLength}
        buttons={props.filterBarContent.buttons}
        dropDownData={props.filterBarContent.dropDownData}
        dropDownPlaceHolder={props.filterBarContent.dropDownPlaceHolder}
      />
      <FilterBarInput
        filterBarInputId={props.filterBarInput.filterBarInputId}
        filterBarInputPlaceHolder={
          props.filterBarInput.filterBarInputPlaceHolder
        }
        filterBarInputType={props.filterBarInput.filterBarInputType}
        filterBarInputValue={props.filterBarInput.filterBarInputValue}
        filterButton={props.filterBarInput.filterButton}
        gridButton={props.filterBarInput.gridButton}
        isGridDisplayMode={props.filterBarInput.isGridDisplayMode}
        itemsFilterBarSort={props.filterBarInput.itemsFilterBarSort}
        listButton={props.filterBarInput.listButton}
        mapButton={props.filterBarInput.mapButton}
        onChange={props.filterBarInput.onChange}
        onClear={props.filterBarInput.onClear}
        onSearch={props.filterBarInput.onSearch}
      />
      <FilterBarSortBy items={props.filterBarSortBy.items} />
    </div>
  );
};
