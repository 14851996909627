import { FC } from 'react'
import { BaseSVGIconProps } from '../../types'
import AbstractSVGIcon from './AbstractSVGIcon';


const KlsPortalLogoSvg:FC<BaseSVGIconProps> = (props) => {
  return (
    <AbstractSVGIcon size={props.size} className={props.className} spin={props.spin} hidden={props.hidden} viewBox="0 0 39 40">
      <g clipPath="url(#clip0_956_3998)">
        <path
          d="M38.4245 30.5482L19.4702 30.4935L6.26337 30.4547C2.8087 30.4459 0.0157413 27.5639 0.0260538 24.0208L0.000272606 33.4849C-0.0100399 37.028 2.78292 39.9083 6.23759 39.9189L19.4461 39.9576L24.5353 39.9717C24.5353 39.9717 38.409 37.1408 38.4176 33.5977L38.4262 30.5464L38.4245 30.5482Z"
          fill="url(#paint0_linear_956_3998)"
        />
        <path
          d="M9.23677 30.4636L9.26598 19.9683L9.3038 6.42343C9.31411 2.88033 12.1225 0.0176477 15.5772 0.0282241L6.34928 2.02972e-05C2.8946 -0.00879338 0.084458 2.85389 0.0758643 6.39699L0.038052 19.9418L0.0260208 24.0208C0.0174271 27.5639 2.80867 30.4442 6.26334 30.4548L9.23677 30.4636Z"
          fill="url(#paint1_linear_956_3998)"
        />
        <path
          d="M29.786 0.273193L29.7327 20.0299L29.6915 33.5748C29.6829 37.1179 26.871 39.9823 23.4198 39.9718L32.6495 40C36.1007 40.0088 38.9126 37.1461 38.9211 33.603L38.9572 20.0581L38.9744 14.9533C38.9744 14.9533 36.2176 0.326075 32.7612 0.315499L29.786 0.273193Z"
          fill="url(#paint2_linear_956_3998)"
        />
        <path
          d="M39.0002 6.5115C39.0088 2.9684 36.2124 0.0880921 32.7612 0.0775157L15.5772 0.0263964C12.1225 0.01582 9.31407 2.88027 9.30376 6.42336L9.29517 9.47466L19.5268 9.50463L32.7354 9.54341C36.1883 9.55398 38.983 12.4343 38.9744 15.9774L38.9985 6.5115H39.0002Z"
          fill="url(#paint3_linear_956_3998)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_956_3998"
          x1="38.7597"
          y1="32.4766"
          x2="0.421003"
          y2="31.9588"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33DAF0" />
          <stop offset="1" stopColor="#04327B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_956_3998"
          x1="15.5033"
          y1="15.3076"
          x2="0.15822"
          y2="15.1015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33DAF0" />
          <stop offset="1" stopColor="#04327B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_956_3998"
          x1="39.2374"
          y1="20.3014"
          x2="23.8906"
          y2="20.0953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33DAF0" />
          <stop offset="1" stopColor="#04327B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_956_3998"
          x1="39.3354"
          y1="8.07681"
          x2="9.67473"
          y2="7.67795"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33DAF0" />
          <stop offset="1" stopColor="#04327B" />
        </linearGradient>
        <clipPath id="clip0_956_3998">
          <rect
            width="39"
            height="40"
            fill="white"
            transform="translate(0.000244141)"
          />
        </clipPath>
      </defs>
    </AbstractSVGIcon>
  );
}
export default KlsPortalLogoSvg;
