import styles from './login-steps.module.css';
import SendMessageIcon from '../../../../shared/assets/icons/email.svg';
import classNames from 'classnames';
import { InputPin, SimpleCard } from '../../../../shared/components/ui';
import { FC, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useHandleTranslation, useTimer } from '../../../../shared/hooks';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

interface LoginStepTwoApiStatus {
    verificationCode?: boolean;
    resendVerificationToken?: boolean;
}

interface LoginSecondStepProps {
    onSubmit: (verificationCode: string) => void;
    isLoading?: LoginStepTwoApiStatus;
    isError?: LoginStepTwoApiStatus;
    isSuccess?: LoginStepTwoApiStatus;
    deadline?: string;
    submitError?: string;
    resendVerificationCode?: () => void;
    className?: string;
    onImpossibleAccess?: () => void;
}

const AuthenticatedVerifyOTP: FC<LoginSecondStepProps> = (props) => {
    const {
        className = '',
        submitError = '',
        deadline,
        isError = { verificationCode: false, resendVerificationToken: false },
        isLoading = { verificationCode: false, resendVerificationToken: false },
        isSuccess = { verificationCode: false, resendVerificationToken: false },
        resendVerificationCode,
        onImpossibleAccess // Destructure new prop
    } = props;

    const { t: tL } = useHandleTranslation('login');
    const { isRunning: isTimerRunning, minutes, seconds } = useTimer(deadline);
    const navigate = useNavigate();

    const containerStyles = classNames({
        [className]: true,
        [styles.secondStepRootContainer]: true
    });

    const submitData = (value: string) => props.onSubmit(value);

    const errorMessageStyles = classNames({
        [styles.serverErrorMessage]: true,
        ['notShow']:
        !props.isError?.verificationCode || props.isLoading?.verificationCode,
        ['show transition-all duration-500']: props.isError?.verificationCode
    });

    const [messageApi, contextHolder] = message.useMessage();
    const alertKey = 'authentication-step-two';

    useEffect(() => {
        if (isLoading.resendVerificationToken) {
            messageApi.loading({
                key: alertKey,
                content: tL('login_stepTwo_resendVerificationCode_message_loading'),
                duration: 0
            });
        }
    }, [isLoading.resendVerificationToken, messageApi, alertKey, tL]);

    useEffect(() => {
        if (isError.resendVerificationToken) {
            messageApi.error({
                key: alertKey,
                content: tL('login_stepTwo_resendVerificationCode_message_error'),
                duration: 2.5
            });
        }
    }, [isError.resendVerificationToken, messageApi, alertKey, tL]);

    useEffect(() => {
        if (isSuccess.resendVerificationToken) {
            messageApi.success({
                key: alertKey,
                content: tL('login_stepTwo_resendVerificationCode_message_success'),
                duration: 2.5
            });
        }
    }, [isSuccess.resendVerificationToken, messageApi, alertKey, tL]);

    useEffect(() => {
        if (isSuccess.verificationCode) {

            navigate('/');
        }
    }, [isSuccess.verificationCode, navigate]);

    const expiredTimerStyles = classNames({
        ['grid grid-cols-2 gap-3']: true,
        ['show']: isTimerRunning,
        ['notDisplayV2']: !isTimerRunning
    });

    const handleImpossibleAccess = async () => {
        if (resendVerificationCode) {
            await resendVerificationCode();
            if (onImpossibleAccess) {
                onImpossibleAccess(); // Move to the next step
            }
        }
    };

    return (
        <SimpleCard className={containerStyles} spin={isLoading.verificationCode}>
            {contextHolder}
            <div className="flex flex-col gap-8 justify-center align-center">
                <div className={'flex justify-center'}>
                    <img src={SendMessageIcon} alt="logo" />
                </div>
                <div className={'flex flex-col justify-center gap-4 text-center'}>
                    <p className={styles.secondStepTitle}>{tL('login_stepTwo_title')}</p>
                    <p className={styles.secondStepDescription}>
                        {tL('login_stepTwo_description')}
                    </p>
                </div>
                <div className="w-3/4 m-auto">
                    <InputPin
                        onSubmit={submitData}
                        empty={isError.verificationCode}
                        upperCase
                    />
                </div>
                <div className="flex flex-col gap-3 ">
                    <div className={errorMessageStyles}>
                        <ExclamationCircleIcon className="size-5 text-red-700" />
                        {tL(submitError)}
                    </div>
                    <div className="flex justify-center items-center min-h-9">
                        <div className={expiredTimerStyles}>
                            <span className={styles.secondStepExpiredTimerLabel}>
                                {tL('login_stepTwo_timer_label')}
                            </span>
                            <span className={styles.secondStepTimer}>
                                {`${minutes} ${tL('minute_unit_label')} ${seconds} ${tL('second_unit_label')}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'flex justify-center flex-col items-center pt-2 space-y-4 '}>
                <span
                    onClick={props.resendVerificationCode}
                    className={styles.secondStepResendActivationLabel}
                >
                    {tL('login_stepTwo_resendVerificationCode_link_label')}
                </span>
                <span
                    onClick={handleImpossibleAccess}
                    className={styles.secondStepResendActivationLabel}
                >
                    {tL('login_stepTwo_impossibleAccederSMS_link_label')}
                </span>
            </div>
        </SimpleCard>
    );
};

export default AuthenticatedVerifyOTP;
