
import { Navigate } from 'react-router-dom';
import { getUserProfile } from '../utils/helpers';
import { getCookiesUser } from '../utils/helpers/local-storage.helper';

const AuthRoute = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = getUserProfile() && getCookiesUser()?.karazalAccessToken;

  if (!isAuthenticated) {
    return <Navigate to="/authentication" replace />;
  }

  return children;
};

export default AuthRoute;
