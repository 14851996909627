import { FC } from 'react';
import { BaseSVGIconProps } from '../../types';
import AbstractSVGIcon from './AbstractSVGIcon';
const GroupesSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M4.00016 10C4.7335 10 5.3335 10.6 5.3335 11.3333C5.3335 12.0667 4.7335 12.6667 4.00016 12.6667C3.26683 12.6667 2.66683 12.0667 2.66683 11.3333C2.66683 10.6 3.26683 10 4.00016 10ZM4.00016 8.66667C2.5335 8.66667 1.3335 9.86667 1.3335 11.3333C1.3335 12.8 2.5335 14 4.00016 14C5.46683 14 6.66683 12.8 6.66683 11.3333C6.66683 9.86667 5.46683 8.66667 4.00016 8.66667ZM8.00016 3.33333C8.7335 3.33333 9.3335 3.93333 9.3335 4.66667C9.3335 5.4 8.7335 6 8.00016 6C7.26683 6 6.66683 5.4 6.66683 4.66667C6.66683 3.93333 7.26683 3.33333 8.00016 3.33333ZM8.00016 2C6.5335 2 5.3335 3.2 5.3335 4.66667C5.3335 6.13333 6.5335 7.33333 8.00016 7.33333C9.46683 7.33333 10.6668 6.13333 10.6668 4.66667C10.6668 3.2 9.46683 2 8.00016 2ZM12.0002 10C12.7335 10 13.3335 10.6 13.3335 11.3333C13.3335 12.0667 12.7335 12.6667 12.0002 12.6667C11.2668 12.6667 10.6668 12.0667 10.6668 11.3333C10.6668 10.6 11.2668 10 12.0002 10ZM12.0002 8.66667C10.5335 8.66667 9.3335 9.86667 9.3335 11.3333C9.3335 12.8 10.5335 14 12.0002 14C13.4668 14 14.6668 12.8 14.6668 11.3333C14.6668 9.86667 13.4668 8.66667 12.0002 8.66667Z" />
  </AbstractSVGIcon>
);
export default GroupesSvg;
