import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const MarketPlaceSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M4.68016 9.00002C3.20683 9.00002 2.0135 10.1934 2.0135 11.6667C2.0135 13.14 3.20683 14.3334 4.68016 14.3334C6.1535 14.3334 7.34683 13.14 7.34683 11.6667C7.34683 10.1934 6.1535 9.00002 4.68016 9.00002ZM4.68016 13C3.94683 13 3.34683 12.4 3.34683 11.6667C3.34683 10.9334 3.94683 10.3334 4.68016 10.3334C5.4135 10.3334 6.0135 10.9334 6.0135 11.6667C6.0135 12.4 5.4135 13 4.68016 13ZM8.66683 9.00002V14.3334H14.0002V9.00002H8.66683ZM12.6668 13H10.0002V10.3334H12.6668V13ZM4.66683 1.66669L1.3335 7.66669H8.00016L4.66683 1.66669ZM4.66683 4.41335L5.7335 6.33335H3.60016L4.66683 4.41335ZM12.8335 2.00002C12.1268 2.00002 11.6268 2.37335 11.3335 2.78002C11.0402 2.37335 10.5402 2.00002 9.8335 2.00002C8.7935 2.00002 8.00016 2.85335 8.00016 3.83335C8.00016 5.16669 9.6135 6.11335 11.3335 7.66669C13.0535 6.11335 14.6668 5.16669 14.6668 3.83335C14.6668 2.85335 13.8735 2.00002 12.8335 2.00002ZM11.3335 5.90002C10.3668 5.08669 9.3335 4.30002 9.3335 3.83335C9.3335 3.54669 9.56683 3.33335 9.8335 3.33335C10.0402 3.33335 10.1802 3.44669 10.3202 3.58002L11.3335 4.53335L12.3468 3.58002C12.4868 3.44669 12.6268 3.33335 12.8335 3.33335C13.1002 3.33335 13.3335 3.54669 13.3335 3.83335C13.3335 4.30002 12.3002 5.08669 11.3335 5.90002Z" />
  </AbstractSVGIcon>
);
export default MarketPlaceSvg;
