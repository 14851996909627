import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const CatalogueSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M0.500488 0V5.33333H5.83382V0H0.500488ZM4.50049 4H1.83382V1.33333H4.50049V4ZM0.500488 6.66667V12H5.83382V6.66667H0.500488ZM4.50049 10.6667H1.83382V8H4.50049V10.6667ZM7.16716 0V5.33333H12.5005V0H7.16716ZM11.1672 4H8.50049V1.33333H11.1672V4ZM7.16716 6.66667V12H12.5005V6.66667H7.16716ZM11.1672 10.6667H8.50049V8H11.1672V10.6667Z" />
  </AbstractSVGIcon>
);
export default CatalogueSvg;
