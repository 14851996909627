import { postGenericKarazActionRequest } from '../../../../shared/utils/helpers';
import {
  SIGN_UP_ACCOUNT_ACTIVATION_PATH,
  SIGN_UP_PATH,
  SIGN_UP_RESEND_ACTIVATION
} from './apiPath';
import { ResponseResult } from '../../../../shared/utils/enums';
import {
  AccountActivation,
  AccountActivationResponse,
  AccountResendActivation,
  AccountResendActivationResponse,
  SignUpUser,
  SingUpDataResponse
} from '../../types';
import { GenericResponseWrapper } from '../../../../shared/types';

export const postSignUpForm = async (data: SignUpUser) => {
  return await postGenericKarazActionRequest<
      SignUpUser,
      GenericResponseWrapper<SingUpDataResponse>
  >(data, SIGN_UP_PATH, ResponseResult.JSON);
};

export const postSignUpActivationAccount = async (data: AccountActivation) => {
  return await postGenericKarazActionRequest<
      AccountActivation,
      GenericResponseWrapper<AccountActivationResponse>
  >(data, SIGN_UP_ACCOUNT_ACTIVATION_PATH, ResponseResult.JSON);
};
export const postSignUpResendActivationCode = async (
    data: AccountResendActivation
): Promise<GenericResponseWrapper<SingUpDataResponse>> => {
  return await postGenericKarazActionRequest<
      AccountResendActivation,
      GenericResponseWrapper<AccountResendActivationResponse>
  >(data, SIGN_UP_RESEND_ACTIVATION, ResponseResult.JSON);
};
