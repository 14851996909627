import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Main from '../pages/Main';
import UserRoutes from './UserRoutes';
import OrganisationRoutes from './OrganisationRoutes';
import AuthRoute from '../../../shared/routes/AuthRoute';

const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<Main />}>
        <Route index element={<Navigate to="utilisateur" replace />} />
        <Route
          path="guichet/*"
          element={
            <AuthRoute>
              <OrganisationRoutes />
            </AuthRoute>
          }
        />
        <Route
          path="utilisateur/*"
          element={
            <AuthRoute>
              <UserRoutes />
            </AuthRoute>
          }
        />
        <Route path="*" element={<Navigate to="utilisateur" replace />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
