import { FC } from 'react';
import TopMenuItem, { TopMenuItemProps } from '../menu-item/TopMenuItem';
import KlsPortalLogoSvg from '../../../../shared/components/icons/KlsPortalLogoSvg';
import { IconSize } from '../../../../shared/utils/enums';
import UserOrgSwitcher, { SwitcherItemProps } from '../menu-item/SwitcherItem';
import styles from './menu.module.css';
import DemoTopMenuButton, {
  DemoTopMenuButtonProps
} from '../../components/ui/buttons/DemoTopMenuButton';
import PlaySvg from '../../../../shared/components/icons/PlaySvg';
import NotificationSvg from '../../../../shared/components/icons/NotificationSvg';
import UserTopMenuItem from '../menu-item/UserTopMenuItem';
import { UserProfile } from '../../../../shared/types';
import {clearCookiesUser, clearUserProfile} from "../../../../shared/utils/helpers";
import {useNavigate} from "react-router-dom";
export interface TopMenuProps {
  topMenuItems: TopMenuItemProps[];
  user?: UserProfile;
  switcherItems: SwitcherItemProps[];
  handleItemClick: (item: string) => void;
  selectedItem: string;
  handleSwitch: (item: string) => void;
  selected: string;
}
const demoButtonElements: DemoTopMenuButtonProps = {
  currentStep: '1',
  totalSteps: '5',
  icon: PlaySvg,
  handleClick: () => {
    console.log('clicked');
  }
};

export const TopMenu: FC<TopMenuProps> = (props) => {
  const navigate=useNavigate();
  const signout=()=>{
    console.log("enter");
    clearUserProfile();
    clearCookiesUser();
    navigate("/authentication")
  }
  console.log(props.user?.id);
  return (
    <div className={styles.topMenu}>
      <KlsPortalLogoSvg size={IconSize.XL} hidden={false}></KlsPortalLogoSvg>
      <div className={styles.topMenuItemsContainer}>
        {props.topMenuItems.map((item: TopMenuItemProps, index: number) => (
          <TopMenuItem
            handleClick={props.handleItemClick}
            selectedItem={props.selectedItem}
            key={index}
            label={item.label}
            link={item.link}
            icon={item.icon}
          ></TopMenuItem>
        ))}
      </div>
      <div className={styles.topMenuSwitcherUser}>
        <DemoTopMenuButton
          currentStep={demoButtonElements.currentStep}
          handleClick={demoButtonElements.handleClick}
          icon={demoButtonElements.icon}
          totalSteps={demoButtonElements.totalSteps}
        />
        <div className={styles.switcherContainer}>
          {props.switcherItems.map((item: SwitcherItemProps, index: number) => (
            <UserOrgSwitcher
              handleClick={props.handleSwitch}
              selectedItem={props.selected}
              key={index}
              label={item.label}
              link={item.link}
              icon={item.icon}
            ></UserOrgSwitcher>
          ))}
        </div>
        <NotificationSvg className={styles.iconNotifColor} />
        <UserTopMenuItem
          avatarUrl={props?.user?.avatarUrl}
          fullName={props?.user?.fullName}
          email={props?.user?.id}
          signout={signout}/>
      </div>
    </div>
  );
};
