import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {ResetPasswordSchema} from '../../holders/schemas';
import styles from './forgetpassword-steps.module.css';
import logo from '../../../../shared/assets/icons/KSW-logo.svg';
import {
    ControlledInputPassword
} from '../../../../shared/components/controlled-ui';
import classNames from 'classnames';
import {FC} from 'react';
import {
    SimpleCard,
    SubmitButton
} from '../../../../shared/components/ui';
import {useHandleTranslation} from '../../../../shared/hooks';
import {ResetPasswordData} from '../../types';

interface ResetPasswordProps {
    onSubmit: (data: ResetPasswordData) => void;
    isLoading?: boolean;
    isError?: boolean | undefined;
    submitError?: string | undefined;
    className?: string;
}

const ForgotPasswordSubmit: FC<ResetPasswordProps> = ({
                                                          className = '',
                                                          isLoading = false,
                                                          ...props
                                                      }) => {
    const {
        control,
        handleSubmit,
        formState: {errors}
    } = useForm<ResetPasswordData>({
        resolver: yupResolver(ResetPasswordSchema)
    });
    const {t: tS} = useHandleTranslation('resetPassword');

    const submitResetPassword = (data: ResetPasswordData) => {
        props.onSubmit(data);
    };

    const containerStyles = classNames({
        [className]: true,
        [styles.resetPasswordRootContainer]: true
    });

    return (
        <SimpleCard className={containerStyles} spin={isLoading}>
            <div className="flex flex-col gap-3">
                <div className="flex items-center gap-8">
                    <img src={logo} alt="" className="size-10"/>
                    <div className="flex flex-col">
            <span className={styles.resetPasswordTitle}>
              {tS('reset_password_title_step_three')}
            </span>
                        <span className={styles.resetPasswordDescription}>
              {tS('reset_password_subtitle_step_three')}
            </span>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className={styles.formContainer}>
                        <ControlledInputPassword
                            size={'sm'}
                            label={tS('reset_password_newPassword')}
                            control={control}
                            name={'password'}
                            args={{placeholder: tS('reset_password_newPasswordPlaceholder')}}
                            error={errors.password?.message && tS(errors.password?.message)}
                        />
                        <ControlledInputPassword
                            size={'sm'}
                            label={tS('reset_password_confirmPassword')}
                            control={control}
                            name={'password2'}
                            args={{
                                placeholder: tS('reset_password_confirmPasswordPlaceholder')
                            }}
                            error={errors.password2?.message && tS(errors.password2?.message)}
                        />
                    </div>
                    <div>
                        <div className={styles.formFooterContainer}>
                            <div className="flex flex-col items-center gap-3">
                                <SubmitButton onClick={handleSubmit(submitResetPassword)}>
                                  <span className={styles.submitButton}>
                                    {tS('reset_password_submit')}
                                  </span>
                                </SubmitButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SimpleCard>
    );
};

export default ForgotPasswordSubmit;
