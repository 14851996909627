import { AbstractData } from '../../types';

export const mapToUrlSearchParams = <T extends AbstractData>(
  obj: T
): URLSearchParams => {
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    params.append(key, String(value));
  });

  return params;
};

export const karazActionResponseAsMap=(mapData: string): object|null =>{
  const pureMapData=mapData.trim();
  if (pureMapData.startsWith("#MAPDATA={") && pureMapData.endsWith("}")) {
    const map: Record<string, string> = {};
    const s = pureMapData.length;
    const tokens = pureMapData.substring(10, s - 1).split(";;");
    for (const token of tokens) {
      const fei = token.indexOf("=");
      if (fei > 0) {
        const key = token.substring(0, fei).trim();
        const value = token.substring(fei + 1).trim();
        map[key] = value;
      }
    }
    return map;
  }
  return null;
}

