import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const CommunauteSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M6.66683 7.33333C8.14016 7.33333 9.3335 6.14 9.3335 4.66667C9.3335 3.19333 8.14016 2 6.66683 2C5.1935 2 4.00016 3.19333 4.00016 4.66667C4.00016 6.14 5.1935 7.33333 6.66683 7.33333ZM6.66683 3.33333C7.40016 3.33333 8.00016 3.93333 8.00016 4.66667C8.00016 5.4 7.40016 6 6.66683 6C5.9335 6 5.3335 5.4 5.3335 4.66667C5.3335 3.93333 5.9335 3.33333 6.66683 3.33333Z" />
    <path d="M2.66683 11.3333C2.8135 10.8533 4.8735 10 6.66683 10C6.66683 9.53333 6.7535 9.08667 6.90016 8.67333C5.08016 8.60667 1.3335 9.51333 1.3335 11.3333V12.6667H7.6935C7.34683 12.28 7.0735 11.8333 6.90016 11.3333H2.66683Z" />
    <path d="M12.9535 11.3467C13.1935 10.9533 13.3335 10.4933 13.3335 10C13.3335 8.52667 12.1402 7.33333 10.6668 7.33333C9.1935 7.33333 8.00016 8.52667 8.00016 10C8.00016 11.4733 9.1935 12.6667 10.6668 12.6667C11.1602 12.6667 11.6202 12.52 12.0135 12.2867C12.6335 12.9067 13.0935 13.3667 13.7268 14L14.6668 13.06C13.6668 12.06 14.1402 12.5267 12.9535 11.3467ZM10.6668 11.3333C9.9335 11.3333 9.3335 10.7333 9.3335 10C9.3335 9.26667 9.9335 8.66667 10.6668 8.66667C11.4002 8.66667 12.0002 9.26667 12.0002 10C12.0002 10.7333 11.4002 11.3333 10.6668 11.3333Z" />
  </AbstractSVGIcon>
);
export default CommunauteSvg;
