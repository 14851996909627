import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const SuppressionSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M4.66683 7.33331V8.66665H11.3335V7.33331H4.66683ZM8.00016 1.33331C4.32016 1.33331 1.3335 4.31998 1.3335 7.99998C1.3335 11.68 4.32016 14.6666 8.00016 14.6666C11.6802 14.6666 14.6668 11.68 14.6668 7.99998C14.6668 4.31998 11.6802 1.33331 8.00016 1.33331ZM8.00016 13.3333C5.06016 13.3333 2.66683 10.94 2.66683 7.99998C2.66683 5.05998 5.06016 2.66665 8.00016 2.66665C10.9402 2.66665 13.3335 5.05998 13.3335 7.99998C13.3335 10.94 10.9402 13.3333 8.00016 13.3333Z" />
  </AbstractSVGIcon>
);
export default SuppressionSvg;
