
export const useHandleFile = (
    file: File|undefined,
    [minSize, maxSize]: [number, number],
    allowedExtensions: string[],
)=> {
  let error=undefined;
  let isValid=true;
  let url=undefined;

    if (!file) {
        error="No file selected";
        isValid=false;
        return {url, error, isValid };
    }

    if (!allowedExtensions.includes(file.type)) {
        error="Format non autorisé. Formats autorisés : .png et .svg";
        isValid=false;
    }
    if (file.size < minSize || file.size > maxSize) {
        error="La taille maximale autorisée est de 2 Mo.";
        isValid=false;
    }
    if(isValid) url =URL.createObjectURL(file);
    return {url,error,isValid}
}