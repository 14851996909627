import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { ControlledUiProps } from '../../types';
import  {Input,InputProps} from 'kls-ui'
export interface ControlledInputProps <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
  extends InputProps, ControlledUiProps<TFieldValues,TName>{}

const ControlledInput  = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>
(props:ControlledInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, onBlur, value,ref } }) => (
        <Input
            ref={ref}
            {...props}
            errorMessage={props.error}
            args={{
                ...props.args,
                onChange: onChange,
                onBlur: onBlur,
                value: value,
            }}
        />
      )}
    />
  );
};

export default ControlledInput;
