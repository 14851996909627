import {OuterContainer} from "../../../../shared/components/ui";


import {OrgCreationStep} from "../../components/ui";

export const Onboarding=()=> {
return (
    <OuterContainer>
        {/*<QAStep/>*/}
        {/*<SpaceConfigStep/>*/}
        <OrgCreationStep/>
        {/*<UploadDocsStep/>*/}
    </OuterContainer>
)
}





