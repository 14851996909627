import { object, string, ref } from 'yup';
import { PasswordStrength } from '../../../../shared/utils/enums';
import { validatePasswordForm } from '../../utils/helpers';

export const UserSignupSchema = object().shape({
  firstName: string().required("sign_stepOne_formError_firstName_required"),
  email: string().email("sign_stepOne_formError_email_invalid").required("sign_stepOne_formError_email_required"),
  lastName: string().required("sign_stepOne_formError_lastName_required"),
  password: string()
    .required("sign_stepOne_formError_password_required")
    .test("",PasswordStrength.WEAK,validatePasswordForm),
  password2: string().oneOf([ref('password'), ""], "sign_stepOne_formError_password2_invalid").required("sign_stepOne_formError_password2_invalid"),
  userName: string().required("Le Nom d'utilisateur est requis"),
  accetterms:string().required("sign_stepOne_formError_accetterms_invalid").oneOf(["true"],'sign_stepOne_formError_accetterms_invalid')
});

