import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const SecuriteSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M8.00024 0.666687L2.00024 3.33335V7.33335C2.00024 11.0334 4.56024 14.4934 8.00024 15.3334C11.4402 14.4934 14.0002 11.0334 14.0002 7.33335V3.33335L8.00024 0.666687ZM8.00024 7.99335H12.6669C12.3136 10.74 10.4802 13.1867 8.00024 13.9534V8.00002H3.33358V4.20002L8.00024 2.12669V7.99335Z" />
  </AbstractSVGIcon>
);
export default SecuriteSvg;
