import { FC } from 'react';
import { Card, CardProps } from '../card/Card';
import styles from './card-grid.module.css'
export interface CardGridProps {
  items: CardProps[];
}
export const CardGird: FC<CardGridProps> = (props) => {
  return (
    <div className={styles.cardsContainer}>
      {props.items.map((item: CardProps, index: number) => (
        <Card
          name={item.name}
          industry={item.name}
          role={item.role}
          favorite={item.favorite}
          status={item.status}
          verification={item.verification}
          key={index}
          imageUrl={item.imageUrl}
        />
      ))}
    </div>
  );
};
