import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const UtilisateursSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M6.00016 9.16665C4.44016 9.16665 1.3335 9.94665 1.3335 11.5V12.6666H10.6668V11.5C10.6668 9.94665 7.56016 9.16665 6.00016 9.16665ZM2.8935 11.3333C3.4535 10.9466 4.80683 10.5 6.00016 10.5C7.1935 10.5 8.54683 10.9466 9.10683 11.3333H2.8935ZM6.00016 7.99998C7.28683 7.99998 8.3335 6.95331 8.3335 5.66665C8.3335 4.37998 7.28683 3.33331 6.00016 3.33331C4.7135 3.33331 3.66683 4.37998 3.66683 5.66665C3.66683 6.95331 4.7135 7.99998 6.00016 7.99998ZM6.00016 4.66665C6.5535 4.66665 7.00016 5.11331 7.00016 5.66665C7.00016 6.21998 6.5535 6.66665 6.00016 6.66665C5.44683 6.66665 5.00016 6.21998 5.00016 5.66665C5.00016 5.11331 5.44683 4.66665 6.00016 4.66665ZM10.6935 9.20665C11.4668 9.76665 12.0002 10.5133 12.0002 11.5V12.6666H14.6668V11.5C14.6668 10.1533 12.3335 9.38665 10.6935 9.20665ZM10.0002 7.99998C11.2868 7.99998 12.3335 6.95331 12.3335 5.66665C12.3335 4.37998 11.2868 3.33331 10.0002 3.33331C9.64016 3.33331 9.30683 3.41998 9.00016 3.56665C9.42016 4.15998 9.66683 4.88665 9.66683 5.66665C9.66683 6.44665 9.42016 7.17331 9.00016 7.76665C9.30683 7.91331 9.64016 7.99998 10.0002 7.99998Z" />
  </AbstractSVGIcon>
);
export default UtilisateursSvg;
