import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserSignupSchema } from '../../holders/schemas';
import { PasswordStrength } from '../../../../shared/utils/enums';
import { PasswordHint } from '../../../../shared/types';
import styles from './signup-steps.module.css';
import logo from '../../../../shared/assets/icons/logo.svg';
import {
  ControlledCheckBox,
  ControlledInput,
  ControlledInputPassword
} from '../../../../shared/components/controlled-ui';
import { validatePasswordStrength } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { SignUpUser } from '../../types';
import { FC } from 'react';
import {
  NotificationCard,
  SimpleCard,
  SubmitButton
} from '../../../../shared/components/ui';
import { useHandleTranslation } from '../../../../shared/hooks';
import { NotificationType } from '../../../../shared/utils/constants';

interface SignUpFirstStepProps {
  onSubmit: (data: SignUpUser) => void;
  isLoading?: boolean;
  isError?: boolean | undefined;
  submitError?: string | undefined;
  className?: string;
}

const SignUpFirstStep: FC<SignUpFirstStepProps> = ({
  className = '',
  submitError = '',
  isLoading = false,
  isError = false,
  ...props
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<SignUpUser>({
    resolver: yupResolver(UserSignupSchema)
  });
  const { t: tS } = useHandleTranslation('signup');
  const submitRegistration = (data: SignUpUser) => {
    props.onSubmit(data);
  };
  const passwordHints: Record<PasswordStrength, PasswordHint> = {
    [PasswordStrength.WEAK]: {
      title: tS('sign_stepOne_password_hint_weak_title'),
      description: tS('sign_stepOne_password_hint_weak_description'),
      errorMessage: tS('sign_stepOne_password_hint_weak_errorMessage')
    },
    [PasswordStrength.MODERATE]: {
      title: tS('sign_stepOne_password_hint_moderate_title'),
      description: tS('sign_stepOne_password_hint_moderate_description')
    },
    [PasswordStrength.STRONG]: {
      title: tS('sign_stepOne_password_hint_strong_title'),
      description: tS('sign_stepOne_password_hint_strong_description')
    }
  };
  const containerStyles = classNames({
    [className]: true,
    [styles.fistStepRootContainer]: true
  });
  const signupErrorStyles = classNames({
    ['notShow']: !isError && !errors?.['accetterms'],
    ['show transition-all duration-500']: isError || errors?.accetterms
  });
  return (
    <SimpleCard className={containerStyles} spin={isLoading}>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-8">
          <img src={logo} alt="" className="size-10" />
          <div className="flex flex-col">
            <span className={styles.firstStepTitle}>
              {tS('sign_stepOne_title')}
            </span>
            <span className={styles.firstStepDescription}>
              {tS('sign_stepOne_subtitle')}
            </span>
          </div>
        </div>
        <NotificationCard
          type={NotificationType.WARNING}
          className={signupErrorStyles}>
          <>
            {(submitError && tS(submitError)) ||
              (errors?.accetterms?.message && tS(errors?.accetterms?.message))}
          </>
        </NotificationCard>
        <div className="flex flex-col gap-4">
          <div className={styles.formContainer}>
            <ControlledInput
              size={'sm'}
              label={tS('sign_stepOne_firstName')}
              control={control}
              name={'firstName'}
              args={{ placeholder: tS('sign_stepOne_firstNamePlaceholder') }}
              error={errors.firstName?.message && tS(errors.firstName?.message)}
            />
            <ControlledInput
              size={'sm'}
              label={tS('sign_stepOne_lastName')}
              control={control}
              name={'lastName'}
              args={{ placeholder: tS('sign_stepOne_lastNamePlaceholder') }}
              error={errors.lastName?.message && tS(errors.lastName?.message)}
            />
            <ControlledInput
              size={'sm'}
              label={tS('sign_stepOne_email')}
              control={control}
              name={'email'}
              args={{ placeholder: tS('sign_stepOne_emailPlaceholder') }}
              error={errors.email?.message && tS(errors.email?.message)}
            />
            <ControlledInput
              size={'sm'}
              label={tS('sign_stepOne_userName')}
              control={control}
              name={'userName'}
              args={{ placeholder: tS('sign_stepOne_userNamePlaceholder') }}
              error={errors.userName?.message && tS(errors.userName?.message)}
              domainTextColor="#2563EB"
              domainText="@karaz.org"
            />
            <ControlledInputPassword
              size={'sm'}
              label={tS('sign_stepOne_password')}
              control={control}
              name={'password'}
              args={{ placeholder: tS('sign_stepOne_passwordPlaceholder') }}
              error={errors.password?.message && tS(errors.password?.message)}
              withProgress
              passwordHints={passwordHints}
              validPasswordStrength={validatePasswordStrength}
            />
            <ControlledInputPassword
              size={'sm'}
              label={tS('sign_stepOne_confirmPassword')}
              control={control}
              name={'password2'}
              args={{
                placeholder: tS('sign_stepOne_confirmPasswordPlaceholder')
              }}
              error={errors.password2?.message && tS(errors.password2?.message)}
            />
          </div>
          <div>
            <div className={styles.formFooterContainer}>
              <div className={styles.termsContainer}>
                <ControlledCheckBox control={control} name={'accetterms'} />
                <span className={styles.terms}>
                  {tS('sign_stepOne_acceptTerms')}
                </span>
                <Link className={styles.redirectLink} to="">
                  {tS('sign_stepOne_termsAndConditions')}
                </Link>
                <span className={styles.terms}>
                  {tS('sign_stepOne_ofKaraz')}
                </span>
              </div>
              <div className="flex flex-col items-center gap-3">
                <SubmitButton onClick={handleSubmit(submitRegistration)}>
                  <span className={styles.submitButton}>
                    {tS('sign_stepOne_signUp')}
                  </span>
                </SubmitButton>
                <div className="flex gap-1">
                  <span className={styles.loginRedirect}>
                    {tS('sign_stepOne_alreadyHaveAccount')}
                  </span>
                  <Link to={'/authentication'} className={styles.redirectLink}>
                    {tS('sign_stepOne_login')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SimpleCard>
  );
};
export default SignUpFirstStep;
