import {FC, ReactNode, useState} from "react";
import classNames from "classnames";
import styles from "./cards.module.css";
import {CheckboxLabel} from "kls-ui";
import {GuichetStatus} from "../../../../../../shared/utils/enums";
import {CheckBadgeIcon, ClockIcon} from "@heroicons/react/16/solid";

interface OrganisationCardProps{
    key:string;
    title: string;
    icon:ReactNode;
    state:GuichetStatus;
    onCheck:()=>void;
}


export const OrganisationCard:FC<OrganisationCardProps>=(props)=>{
    const [isChecked,setIsChecked]=useState<boolean>(false)
   const handleCheckValue=(value:boolean)=>{
        setIsChecked(value);
        props.onCheck();
   }

    const rootStyle=classNames({
        [styles.orgCardContainer]:true,
        [styles.orgCardSelectedContainer]:isChecked,
        [styles["onboarding-card-css-var"]]:true,
    })
    const renderStateIcon=()=>{
        switch (props.state){
            case GuichetStatus.IN_PROGRESS: return <ClockIcon className="size-3 text-orange-600 "/>;
            case GuichetStatus.VERIFIED:  return <CheckBadgeIcon className="size-3 text-emerald-500"/>
        }
    }
    return (
        <div className={rootStyle}>
            <div className="flex items-center gap-2">
                <div className="flex justify-center">{props.icon}</div>
                <div className="flex items-center gap-1">
                    <span className={styles.configCardTitle}>{props.title}</span>
                    {renderStateIcon()}
                </div>

            </div>
            <CheckboxLabel Check={isChecked} handleChangeValue={handleCheckValue}/>
        </div>
)
}
