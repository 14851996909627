import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './menuItem.module.css';
import classNames from 'classnames';
import { BaseSVGIconProps } from '../../../../shared/types';
import { IconSize } from '../../../../shared/utils/enums';
export interface SidebarMenuItemProps {
  selectedItem: string;
  handleClick: (item: string) => void;
  label: string;
  link: string;
  icon: FC<BaseSVGIconProps>;
  isOpen?: boolean;
  blockEnd?: boolean;
  isForDelete?: boolean;
}

const SidebarMenuItemStyle = (props: SidebarMenuItemProps) =>
  classNames({
    [styles.menuItem]: true,
    [styles.endBolck]: props.blockEnd,
    ['justify-center']: !props.isOpen,
    [styles.innerLeftBorder]:
      props.label === props.selectedItem && !props.isForDelete,
    [styles.innerLeftBorderSupp]:
      props.label === props.selectedItem && props.isForDelete,
    [styles.menuItemColor]:
      props.label !== props.selectedItem && !props.isForDelete
  });

const iconColor = (props: SidebarMenuItemProps) => {
  return props.label === props.selectedItem && !props.isForDelete
    ? 'blue-600'
    : props.isForDelete
      ? 'red-600'
      : 'slate-400';
};

const linkStyle = (props: SidebarMenuItemProps) => {
  if (props.isForDelete) return { color: 'var(--red-600)' };
  return;
};

const SidebarMenuItem: FC<SidebarMenuItemProps> = (props) => {
  return (
    <Link
      onClick={() => props.handleClick(props.label)}
      style={linkStyle(props)}
      className={SidebarMenuItemStyle(props)}
      to={props.link}
    >
      <props.icon size={IconSize.SM} className={iconColor(props)} hidden={false}></props.icon>
      {props.isOpen && props.label}
    </Link>
  );
};
export default SidebarMenuItem;
