import FilterSvg from '../../../shared/components/icons/FilterSvg';
import GridSvg from '../../../shared/components/icons/GridSvg';
import HomeSvg from '../../../shared/components/icons/HomeSvg';
import ListSvg from '../../../shared/components/icons/ListSvg';
import LocalisationSvg from '../../../shared/components/icons/LocalisationSvg';
import PeopleSvg from '../../../shared/components/icons/PeopleSvg';
import StarSvg from '../../../shared/components/icons/StarSvg';
import { FilterBarContentProps } from '../components/filter-bar/FilterBarContent';
import { FilterBarHeaderProps } from '../components/filter-bar/FilterBarHeader';
import { FilterBarInputProps } from '../components/filter-bar/FilterBarInput';
import { FilterBarSortByProps } from '../components/filter-bar/FilterBarSortBy';

export const fBh: FilterBarHeaderProps = {
  hasChilds: true,
  icon: HomeSvg,
  parent: 'Guichets',
  childs:['Guichet', 'Store']
};
export const fBc: FilterBarContentProps = {
  label: 'Guichets',
  searchLength: 10,
  buttons: [
    {
      icon: StarSvg,
      label: 'Mes favorites'
    },
    {
      icon: PeopleSvg,
      label: 'Où je contribue'
    }
  ],
  dropDownData: [
    {
      label: 'Créer organisation',
      value: 'new'
    },
    {
      label: 'Rejoindre une organisation',
      value: 'existing'
    }
  ],
  dropDownPlaceHolder: 'Nouveau guichet'
};
export const fBi: FilterBarInputProps = {
  filterBarInputId: 'search',
  filterBarInputPlaceHolder: 'Rechercher',
  filterBarInputType: 'text',
  filterBarInputValue: '',
  filterButton: {
    handleClick: () => console.log('fliterButton'),
    icon: FilterSvg,
    label: 'Filtres'
  },
  mapButton: {
    handleClick: () => console.log('mapButton'),
    icon: LocalisationSvg
  },gridButton: {
    handleClick: () => console.log('gridButton'),
    icon: GridSvg
  },
  listButton: {
    handleClick: () => console.log('listButton'),
    icon: ListSvg
  },
  isGridDisplayMode: false,
  itemsFilterBarSort: {
    label: 'Trier par:',
    options: [
      {
        label: 'Créer organisation',
        value: 'new'
      },
      {
        label: 'Rejoindre une organisation',
        value: 'existing'
      }
    ],
    selectedValue: 'Récent'
  },
  onChange: () => console.log('fbi onchange'),
  onClear: () => console.log('fbi onclear'),
  onSearch: () => console.log('fbi onclear')
};
export const fBs: FilterBarSortByProps = {
  items: [
    {
      data: [
        {
          label: 'Créer organisation',
          value: 'new'
        },
        {
          label: 'Rejoindre une organisation',
          value: 'existing'
        }
      ],
      label: 'Industrie(s)',
      placeHolder: 'Industrie',
      selectedValue: ''
    },
    {
      data: [
        {
          label: 'Créer organisation',
          value: 'new'
        },
        {
          label: 'Rejoindre une organisation',
          value: 'existing'
        }
      ],
      label: 'Vérification Guichet',
      placeHolder: 'Vérification Guichet',
      selectedValue: ''
    },
    {
      data: [
        {
          label: 'Créer organisation',
          value: 'new'
        },
        {
          label: 'Rejoindre une organisation',
          value: 'existing'
        }
      ],
      label: 'Rôle',
      placeHolder: 'Rôle',
      selectedValue: ''
    },
    {
      data: [
        {
          label: 'Créer organisation',
          value: 'new'
        },
        {
          label: 'Rejoindre une organisation',
          value: 'existing'
        }
      ],
      label: 'Statut Rôle',
      placeHolder: 'Statut Rôle',
      selectedValue: ''
    }
  ]
};
