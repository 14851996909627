import React from 'react';
import ReactDOM from 'react-dom/client';
import './shared/styles/index.css';
import './shared/styles/colors.css';
import './shared/styles/variables.css';
import App from './App';
import './i18n.ts';
import {KSWAntdProvider} from "./shared/context";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
      <KSWAntdProvider>
          <App />
      </KSWAntdProvider>
  </React.StrictMode>
);
