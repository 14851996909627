import { ChangeEvent, FocusEvent, useEffect, ForwardRefRenderFunction, forwardRef } from 'react';
import { Input } from 'kls-ui';
import {useState } from 'react';
import styles from './inputPassword.module.css'
import classNames from 'classnames';
import { PasswordStrength } from '../../../utils/enums';
import { InputPasswordProps } from '../../../types';



const InputPassword:ForwardRefRenderFunction<HTMLInputElement,InputPasswordProps>=({withProgress=false,errorMessage,...props},ref)=>{
const [isProgressBarDisplayed,setDisplayedProgressBar]=useState<boolean>(false);
const [passwordStrength,setPasswordStrength]=useState<PasswordStrength |null >(null)
const progressStyles=classNames({
     [styles.progressBar]:true,
     [styles.progressBarSuccess]:passwordStrength===PasswordStrength.STRONG,
     [styles.progressBarWarning]:passwordStrength===PasswordStrength.MODERATE,
     [styles.progressBarDanger]:passwordStrength===PasswordStrength.WEAK,
   })

  const overrideBlur=(event: FocusEvent<HTMLInputElement>)=>{
  if(withProgress) setDisplayedProgressBar(false) ;
  if(props.args?.onBlur)  props.args.onBlur(event);
  }
  const overrideFocus=(event: FocusEvent<HTMLInputElement>)=>{
    if(withProgress && event.target.value.length !==0 ) setDisplayedProgressBar(true) ;
    if(props.args?.onFocus) props.args.onFocus(event);
  }

  const overrideChange=(event:ChangeEvent<HTMLInputElement>)=>{
   if (event.target.value.length===0) setDisplayedProgressBar(false);
   else if(!isProgressBarDisplayed) setDisplayedProgressBar(true);
   if (withProgress){
     if (errorMessage && (errorMessage in PasswordStrength)) setPasswordStrength(PasswordStrength[errorMessage as keyof typeof PasswordStrength])
     else if (props.validPasswordStrength ) setPasswordStrength(props.validPasswordStrength(event.target.value))
   }
     if(props.args?.onChange) props.args.onChange(event);
  }

  useEffect(() => {
    if (withProgress && !passwordStrength  &&  errorMessage && (errorMessage in PasswordStrength)) setPasswordStrength(PasswordStrength[errorMessage as keyof typeof PasswordStrength]);
  }, [errorMessage]);

const errorMessageRenderer=():object=>{
    if (!withProgress) return {errorMessage}
    if (withProgress && !isProgressBarDisplayed && errorMessage && !(errorMessage in PasswordStrength)) return {errorMessage}
    if(withProgress &&  !isProgressBarDisplayed ) {
    if (passwordStrength && errorMessage && props.passwordHints) return { errorMessage: props.passwordHints[passwordStrength].errorMessage }
    if (!passwordStrength && errorMessage && !(errorMessage in PasswordStrength)) return { errorMessage }
    }
    return {}
}
  return (<div>
     <Input
      ref={ref}
      {...props}
      {...errorMessageRenderer()}
      args={{
        ...props.args,
        type:"password",
        onBlur:overrideBlur,
        onFocus:overrideFocus,
        onChange:overrideChange,
        autoComplete:"off",
      }}
      withoutError={Object.keys(errorMessageRenderer()).length==0}
    />
    <div className={styles.fixedProgressBar}>
    {withProgress && passwordStrength && isProgressBarDisplayed && props.passwordHints &&
      <div className={styles.progressContainer}>
        <div className={styles.progress}>
          <div className={progressStyles} role="progress-bar"/>
        </div>
        <div className="flex gap-2">
             <span className={styles.hintTitle}>{props.passwordHints[passwordStrength].title}</span>
             <span className={styles.hintDescription}>{props.passwordHints[passwordStrength].description}</span>
       </div>
  </div>}
    </div>
  </div>)
    }

export default forwardRef(InputPassword);