import { NotificationType } from '../../../utils/constants';
import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './cards.module.css';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon
} from '@heroicons/react/24/outline';

interface NotificationCardProps {
  type: NotificationType;
  children: ReactNode;
  className?: string;
}

export const NotificationCard: FC<NotificationCardProps> = (props) => {
  const { className = '' } = props;
  const rootStyles = classNames({
    [className]: true,
    [styles.notificationCardContainer]: true,
    [styles.notificationCardInfo]: props.type == NotificationType.INFO,
    [styles.notificationCardSuccess]: props.type == NotificationType.SUCCESS,
    [styles.notificationCardWarning]: props.type == NotificationType.WARNING,
    [styles.notificationCardError]: props.type == NotificationType.ERROR
  });

  const renderIcon = (type: NotificationType) => {
    switch (type) {
      case NotificationType.SUCCESS:
        return <CheckCircleIcon className={'size-5'} />;
      case NotificationType.ERROR:
        return <XCircleIcon className={'size-5'} />;
      case NotificationType.WARNING:
        return <ExclamationCircleIcon className={'size-5'} />;
      case NotificationType.INFO:
        return <ExclamationCircleIcon className={'size-5'} />;
    }
    return <></>;
  };

  return (
    <div className={rootStyles}>
      {renderIcon(props.type)}
      {props.children}
    </div>
  );
};
