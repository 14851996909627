import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const UtilisateurSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M6.00081 1.93329C6.77415 1.93329 7.40081 2.55996 7.40081 3.33329C7.40081 4.10663 6.77415 4.73329 6.00081 4.73329C5.22748 4.73329 4.60081 4.10663 4.60081 3.33329C4.60081 2.55996 5.22748 1.93329 6.00081 1.93329ZM6.00081 7.93329C7.98081 7.93329 10.0675 8.90663 10.0675 9.33329V10.0666H1.93415V9.33329C1.93415 8.90663 4.02081 7.93329 6.00081 7.93329ZM6.00081 0.666626C4.52748 0.666626 3.33415 1.85996 3.33415 3.33329C3.33415 4.80663 4.52748 5.99996 6.00081 5.99996C7.47415 5.99996 8.66748 4.80663 8.66748 3.33329C8.66748 1.85996 7.47415 0.666626 6.00081 0.666626ZM6.00081 6.66663C4.22081 6.66663 0.66748 7.55996 0.66748 9.33329V11.3333H11.3341V9.33329C11.3341 7.55996 7.78081 6.66663 6.00081 6.66663Z" />
  </AbstractSVGIcon>
);
export default UtilisateurSvg;
