import { CardProps } from "../components/card/Card";



export const guichets: CardProps[] = [
  {
    name: 'Inwi',
    industry: 'Telecom',
    role: 'User',
    status: 'Verified',
    verification: 'Pending',
    favorite: true,
    imageUrl: '/pictures/example-air-royale.png'
  },
  {
    name: '710 Single Window',
    industry: 'Government',
    role: 'Owner',
    status: 'Verified',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png'
  },
  {
    name: 'Microsoft',
    industry: 'Technology',
    role: 'User',
    status: 'Verified',
    verification: 'Completed',
    favorite: true,
    imageUrl: '/pictures/example-air-royale.png'
  },
  {
    name: 'OPEL Engines',
    industry: 'Automotive',
    role: 'User',
    status: 'Verified',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png'
  },
  {
    name: '1337 Coding School',
    industry: 'Education',
    role: 'User',
    status: 'Verified',
    verification: 'Pending',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png'
  },
  {
    name: 'Multicast e-services shop',
    industry: 'Technology',
    role: 'Citizen Developer',
    status: 'Pending',
    verification: 'In Progress',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png'
  },
  {
    name: 'UM6P',
    industry: 'Education',
    role: 'User',
    status: 'Verified',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png'
  },
  {
    name: 'OPEL SAV',
    industry: 'Automotive',
    role: 'User',
    status: 'Verified',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png'
  },
  {
    name: 'Ribatis',
    industry: 'Technology',
    role: 'Owner',
    status: 'Verified',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png'
  },
  {
    name: 'Royal Air Maroc',
    industry: 'Aviation',
    role: 'Administrator',
    status: 'Verified',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png'
  }
];

export const filters = {
  industry: 'Technology',
  verification: 'Verified',
  role: 'User',
  status: 'Completed'
};

export const sortBy = 'Recent';
