import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const ContributeursSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M2.72347 11.6666V11.2333C2.72347 11.0066 2.83014 10.7933 2.9968 10.6933C4.12347 10.02 5.41014 9.66665 6.72347 9.66665C6.74347 9.66665 6.7568 9.66665 6.7768 9.67331C6.84347 9.20665 6.9768 8.75998 7.17014 8.35331C7.02347 8.33998 6.8768 8.33331 6.72347 8.33331C5.11014 8.33331 3.60347 8.77998 2.3168 9.54665C1.73014 9.89331 1.39014 10.5466 1.39014 11.2333V13H7.56347C7.28347 12.6 7.06347 12.1466 6.9168 11.6666H2.72347Z" />
    <path d="M6.72347 7.66665C8.1968 7.66665 9.39014 6.47331 9.39014 4.99998C9.39014 3.52665 8.1968 2.33331 6.72347 2.33331C5.25014 2.33331 4.0568 3.52665 4.0568 4.99998C4.0568 6.47331 5.25014 7.66665 6.72347 7.66665ZM6.72347 3.66665C7.4568 3.66665 8.0568 4.26665 8.0568 4.99998C8.0568 5.73331 7.4568 6.33331 6.72347 6.33331C5.99014 6.33331 5.39014 5.73331 5.39014 4.99998C5.39014 4.26665 5.99014 3.66665 6.72347 3.66665Z" />
    <path d="M13.8901 10.3333C13.8901 10.1866 13.8701 10.0533 13.8501 9.91331L14.6101 9.23998L13.9435 8.08665L12.9768 8.41331C12.7635 8.23331 12.5235 8.09331 12.2568 7.99331L12.0568 6.99998H10.7235L10.5235 7.99331C10.2568 8.09331 10.0168 8.23331 9.80347 8.41331L8.8368 8.08665L8.17014 9.23998L8.93014 9.91331C8.91014 10.0533 8.89014 10.1866 8.89014 10.3333C8.89014 10.48 8.91014 10.6133 8.93014 10.7533L8.17014 11.4266L8.8368 12.58L9.80347 12.2533C10.0168 12.4333 10.2568 12.5733 10.5235 12.6733L10.7235 13.6666H12.0568L12.2568 12.6733C12.5235 12.5733 12.7635 12.4333 12.9768 12.2533L13.9435 12.58L14.6101 11.4266L13.8501 10.7533C13.8701 10.6133 13.8901 10.48 13.8901 10.3333ZM11.3901 11.6666C10.6568 11.6666 10.0568 11.0666 10.0568 10.3333C10.0568 9.59998 10.6568 8.99998 11.3901 8.99998C12.1235 8.99998 12.7235 9.59998 12.7235 10.3333C12.7235 11.0666 12.1235 11.6666 11.3901 11.6666Z" />
  </AbstractSVGIcon>
);
export default ContributeursSvg;
