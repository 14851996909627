import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const PlaySvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox='0 0 18 18'
  >
    <path d="M10.0008 1.66663C5.40081 1.66663 1.66748 5.39996 1.66748 9.99996C1.66748 14.6 5.40081 18.3333 10.0008 18.3333C14.6008 18.3333 18.3341 14.6 18.3341 9.99996C18.3341 5.39996 14.6008 1.66663 10.0008 1.66663ZM10.0008 16.6666C6.32581 16.6666 3.33415 13.675 3.33415 9.99996C3.33415 6.32496 6.32581 3.33329 10.0008 3.33329C13.6758 3.33329 16.6675 6.32496 16.6675 9.99996C16.6675 13.675 13.6758 16.6666 10.0008 16.6666ZM7.91748 13.75L13.7508 9.99996L7.91748 6.24996V13.75Z" />
  </AbstractSVGIcon>
);
export default PlaySvg;
