import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const NotificationSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox='0 0 14 18'
  >
    <path d="M7.00065 17.125C7.91732 17.125 8.66732 16.375 8.66732 15.4583H5.33398C5.33398 16.375 6.08398 17.125 7.00065 17.125ZM12.0007 12.125V7.95833C12.0007 5.4 10.6423 3.25833 8.25065 2.69167V2.125C8.25065 1.43333 7.69232 0.875 7.00065 0.875C6.30898 0.875 5.75065 1.43333 5.75065 2.125V2.69167C3.36732 3.25833 2.00065 5.39167 2.00065 7.95833V12.125L0.333984 13.7917V14.625H13.6673V13.7917L12.0007 12.125ZM10.334 12.9583H3.66732V7.95833C3.66732 5.89167 4.92565 4.20833 7.00065 4.20833C9.07565 4.20833 10.334 5.89167 10.334 7.95833V12.9583Z" />
  </AbstractSVGIcon>
);
export default NotificationSvg;
