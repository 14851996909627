import dashboard from '../../../../shared/components/icons/DashboardSvg';
import organisationS from '../../../../shared/components/icons/OrganisationSvg';
import applications from '../../../../shared/components/icons/ApplicationsSvg';
import offre from '../../../../shared/components/icons/OffreSvg';
import parametres from '../../../../shared/components/icons/ParametresSvg';
import monitoring from '../../../../shared/components/icons/MonitoringSvg';
import securite from '../../../../shared/components/icons/SecuriteSvg';
import contributeurs from '../../../../shared/components/icons/ContributeursSvg';
import utilisateurs from '../../../../shared/components/icons/UtilisateursSvg';
import groupes from '../../../../shared/components/icons/GroupesSvg';
import developerZone from '../../../../shared/components/icons/DeveloperZoneSvg';
import marketPlace from '../../../../shared/components/icons/MarketPlaceSvg';
import communaute from '../../../../shared/components/icons/CommunauteSvg';
import catalogue from '../../../../shared/components/icons/CatalogueSvg';
import support from '../../../../shared/components/icons/SupportSvg';
import suppression from '../../../../shared/components/icons/SuppressionSvg';
import academy from '../../../../shared/components/icons/AcademySvg';
import utilisateur from '../../../../shared/components/icons/UtilisateurSvg';
import { SidebarMenuItemProps } from './SidebarMenuItem';
import { TopMenuItemProps } from './TopMenuItem';
import { SwitcherItemProps } from './SwitcherItem';

import { useHandleTranslation } from '../../../../shared/hooks';

export const useMenuItems = (
  selectedRoute: string,
  organisationId: string
): SidebarMenuItemProps[] => {
  const { t: tSm } = useHandleTranslation('menu');

  return [
    {
      label: tSm('menu.dashboard'),
      icon: dashboard,
      link: `${selectedRoute}/${organisationId}`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.guichet'),
      icon: organisationS,
      link: `${selectedRoute}/${organisationId}/guichet`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.eservices'),
      icon: applications,
      link: `${selectedRoute}/${organisationId}/eservices`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.offre'),
      icon: offre,
      link: `${selectedRoute}/${organisationId}/offre`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.parametres'),
      icon: parametres,
      link: `${selectedRoute}/${organisationId}/parameters`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.monitoring'),
      icon: monitoring,
      link: `${selectedRoute}/${organisationId}/monitoring`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.securite'),
      icon: securite,
      link: `${selectedRoute}/${organisationId}/security`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.contributeurs'),
      icon: contributeurs,
      link: `${selectedRoute}/${organisationId}/contributors`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.utilisateurs'),
      icon: utilisateurs,
      link: `${selectedRoute}/${organisationId}/users`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.groupes'),
      icon: groupes,
      link: `${selectedRoute}/${organisationId}/groups`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.developer_zone'),
      icon: developerZone,
      link: `${selectedRoute}/${organisationId}/devzone`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.market_place'),
      icon: marketPlace,
      link: `${selectedRoute}/${organisationId}/marketplace`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.communaute'),
      icon: communaute,
      link: `${selectedRoute}/${organisationId}/communaute`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.suppression_organisation'),
      icon: suppression,
      link: `${selectedRoute}/${organisationId}/deleteorganisation`,
      isForDelete: true,
      selectedItem: '',
      handleClick: () => {}
    }
  ];
};

export const useTopMenuItems = (selectedRoute: string): TopMenuItemProps[] => {
  const { t: tTm } = useHandleTranslation('menu');

  return [
    {
      label: tTm('top_menu.accueil'),
      link: `${selectedRoute}`,
      selectedItem: '',
      icon: dashboard,
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.guichets'),
      link: `${selectedRoute}/guichets`,
      selectedItem: '',
      icon: organisationS,
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.catalogue'),
      link: `${selectedRoute}/catalogue`,
      selectedItem: '',
      icon: catalogue,
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.offre'),
      link: `${selectedRoute}/offre`,
      selectedItem: '',
      icon: offre,
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.academy'),
      link: `${selectedRoute}/academy`,
      selectedItem: '',
      icon: academy,
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.support'),
      link: `${selectedRoute}/support`,
      selectedItem: '',
      icon: support,
      handleClick: () => {}
    }
  ];
};

export const useSwitcherItems = (): SwitcherItemProps[] => {
  const { t: tSw } = useHandleTranslation('menu');

  return [
    {
      label: tSw('switcher.guichet'),
      link: 'guichet',
      selectedItem: '',
      icon: organisationS,
      handleClick: () => {}
    },
    {
      label: tSw('switcher.utilisateur'),
      link: 'utilisateur',
      selectedItem: '',
      icon: utilisateur,
      handleClick: () => {}
    }
  ];
};
