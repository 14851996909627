import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AccueilPage } from '../pages/main-pages/AccueilPage';
import { OrganisationPage } from '../pages/organisation-pages/OrganisationPage';
import { DashboardPage } from '../pages/organisation-pages/DashboardPage';
import { ApplicationsPage } from '../pages/organisation-pages/ApplicationsPage';
import { OffreOrgPage } from '../pages/organisation-pages/OffreOrgPage';
import { ParametresPage } from '../pages/organisation-pages/ParametresPage';
import { MonitoringPage } from '../pages/organisation-pages/MonitoringPage';
import { SecuritePage } from '../pages/organisation-pages/SecuritePage';
import { ContributeurPage } from '../pages/organisation-pages/ContributeurPage';
import { GroupesPage } from '../pages/organisation-pages/GroupesPage';
import { MarketPlacePage } from '../pages/organisation-pages/MarketPlacePage';
import { CommunautePage } from '../pages/organisation-pages/CommunautePage';
import { SuppressionPage } from '../pages/organisation-pages/SuppressionPage';
import { OffrePage } from '../pages/main-pages/OffrePage';
import { AcademyPage } from '../pages/main-pages/AcademyPage';
import { CataloguePage } from '../pages/main-pages/CataloguePage';
import { SupportPage } from '../pages/main-pages/SupportPage';
import { UtilisateursPage } from '../pages/organisation-pages/UtilisateursPage';
import { DeveloperZonePage } from '../pages/organisation-pages/DeveloperZonePage';
import { OrganisationsPage } from '../pages/main-pages/OrganisationsPage';

const OrganisationRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<AccueilPage />} />
      <Route path="guichets">
        <Route
          index
          element={
              <OrganisationsPage />
          }
        />
        <Route path=":guichetId">
          <Route index element={<DashboardPage />} />
          <Route path="guichet" element={<OrganisationPage />} />
          <Route path="eservices" element={<ApplicationsPage />} />
          <Route path="offre" element={<OffreOrgPage />} />
          <Route path="parameters" element={<ParametresPage />} />
          <Route path="monitoring" element={<MonitoringPage />} />
          <Route path="security" element={<SecuritePage />} />
          <Route path="contributors" element={<ContributeurPage />} />
          <Route path="users" element={<UtilisateursPage />} />
          <Route path="groups" element={<GroupesPage />} />
          <Route path="devzone" element={<DeveloperZonePage />} />
          <Route path="marketplace" element={<MarketPlacePage />} />
          <Route path="communaute" element={<CommunautePage />} />
          <Route path="deleteorganisation" element={<SuppressionPage />} />
          <Route path="*" element={<Navigate to="" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="" replace />} />
      </Route>
      <Route path="offre" element={<OffrePage />} />
      <Route path="academy" element={<AcademyPage />} />
      <Route path="catalogue" element={<CataloguePage />} />
      <Route path="support" element={<SupportPage />} />
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default OrganisationRoutes;
