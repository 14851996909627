import { karazActionResponseAsMap, mapToUrlSearchParams } from '../mapper';
import { AbstractData, ApiRemoteAction } from '../../types';
import { ResponseResult } from '../enums';

export const postGenericKarazActionRequest=async <T extends AbstractData,R> (
  data:T,
  karazPath:ApiRemoteAction,
  responseType:ResponseResult):Promise<R>=>{
  const formData: URLSearchParams = mapToUrlSearchParams(data);
  Object.keys(karazPath).forEach((karazPathKey)=>{formData.append(karazPathKey,karazPath[karazPathKey as keyof ApiRemoteAction]);});
  if( responseType===ResponseResult.JSON) return await postFormAsJson(formData,karazPath.path);
   return (await postFormAsMapResponse(formData,karazPath.path) as R);
}



export const postFormAsMapResponse = async <R> (
  form: URLSearchParams,
  url: string
): Promise<R> => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: form
  })
    .then((r) => r.text())
    .then((data) => (karazActionResponseAsMap(data)as R));
};



export const postFormAsJson = async<R> (
  form: URLSearchParams,
  url: string
): Promise<R> => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: form
  }).then((r) => r.json());
};


