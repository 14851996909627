import { ControlledUiProps, InputPasswordProps } from '../../types';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { InputPassword } from '../ui';

export interface ControlledInputPasswordProps<TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>> extends InputPasswordProps, ControlledUiProps<TFieldValues, TName> {}

const ControlledInputPassword= <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>(props:ControlledInputPasswordProps<TFieldValues, TName>) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, onBlur, value ,ref} }) => (
        <InputPassword
          ref={ref}
          {...props}
          errorMessage={props.error}
          args={{
            ...props.args,
            onChange: onChange,
            onBlur: onBlur,
            value: value
          }}
        />
      )}
    />
  );
};

export default ControlledInputPassword;