import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const OrganisationSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M8.00016 4.66667V2H1.3335V14H14.6668V4.66667H8.00016ZM6.66683 12.6667H2.66683V11.3333H6.66683V12.6667ZM6.66683 10H2.66683V8.66667H6.66683V10ZM6.66683 7.33333H2.66683V6H6.66683V7.33333ZM6.66683 4.66667H2.66683V3.33333H6.66683V4.66667ZM13.3335 12.6667H8.00016V6H13.3335V12.6667ZM12.0002 7.33333H9.3335V8.66667H12.0002V7.33333ZM12.0002 10H9.3335V11.3333H12.0002V10Z" />
  </AbstractSVGIcon>
);
export default OrganisationSvg;
