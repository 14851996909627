import  {ReactNode, FC } from 'react';
import styles from './submit-button.module.css'
import { LoadingOutlined } from '../../icons';
import classNames from "classnames";
interface SubmitButtonProps {
  onClick:()=>(void);
  isLoading?:boolean;
  children:ReactNode;
  rootClassName?: string;
}


const SubmitButton:FC<SubmitButtonProps>=({isLoading=false,rootClassName="",...props})=> {

  const rootElementStyle=classNames({
    [styles.submitButton]:true,
    [rootClassName]:rootClassName!==""
  })
  return (
  <button onClick={props.onClick} className={rootElementStyle}>
    <div className={"flex gap-1 items-center justify-center "}>
      {isLoading && <LoadingOutlined spin={isLoading} className="text-blue-50" hidden={!isLoading} />}
      {props.children}
    </div>
  </button>)
}
export default SubmitButton;