export enum PasswordStrength {
  WEAK="WEAK",
  MODERATE="MODERATE",
  STRONG="STRONG"
}
export enum ResponseResult {
  JSON="JSON",
  MAP_DATA="MAP_DATA"
}
export enum  IconSize  {
  XS='8px',
  SM='16px',
  MD= '24px',
  XL='32px',
  PX10='10px',
  PX12='12px',
  PX14='14px',
  PX18='18px',
}
export enum GuichetStatus{
  VERIFIED="verified",
  IN_PROGRESS="in progress",
}
