import { FC } from 'react';
import { BaseSVGIconProps } from '../../../../../shared/types';
import classNames from 'classnames';
import styles from './buttons.module.css';
export interface DemoTopMenuButtonProps {
  currentStep: string;
  totalSteps: string;
  icon: FC<BaseSVGIconProps>;
  handleClick: () => void;
}
const demoTopMenuButtonStyle = () =>
  classNames({
    [styles.demoButton]: true
  });
const contentButtonStyle = () =>
  classNames({
    [styles.contentDemoButton]: true
  });
const DemoTopMenuButton: FC<DemoTopMenuButtonProps> = (props) => {
  return (
    <button className={demoTopMenuButtonStyle()}>
      <props.icon className={contentButtonStyle()} />
      <span className={contentButtonStyle()}>
        {props.currentStep + '/' + props.totalSteps}
      </span>
    </button>
  );
};

export default DemoTopMenuButton;
