import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const AcademySvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M6.83374 11H8.16707V9.66671H6.83374V11ZM7.50041 0.333374C3.82041 0.333374 0.83374 3.32004 0.83374 7.00004C0.83374 10.68 3.82041 13.6667 7.50041 13.6667C11.1804 13.6667 14.1671 10.68 14.1671 7.00004C14.1671 3.32004 11.1804 0.333374 7.50041 0.333374ZM7.50041 12.3334C4.56041 12.3334 2.16707 9.94004 2.16707 7.00004C2.16707 4.06004 4.56041 1.66671 7.50041 1.66671C10.4404 1.66671 12.8337 4.06004 12.8337 7.00004C12.8337 9.94004 10.4404 12.3334 7.50041 12.3334ZM7.50041 3.00004C6.02707 3.00004 4.83374 4.19337 4.83374 5.66671H6.16707C6.16707 4.93337 6.76707 4.33337 7.50041 4.33337C8.23374 4.33337 8.83374 4.93337 8.83374 5.66671C8.83374 7.00004 6.83374 6.83337 6.83374 9.00004H8.16707C8.16707 7.50004 10.1671 7.33337 10.1671 5.66671C10.1671 4.19337 8.97374 3.00004 7.50041 3.00004Z" />
  </AbstractSVGIcon>
);
export default AcademySvg;
