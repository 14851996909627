import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const ParametresSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M2.00024 11.3333V12.6667H6.00024V11.3333H2.00024ZM2.00024 3.33333V4.66667H8.66691V3.33333H2.00024ZM8.66691 14V12.6667H14.0002V11.3333H8.66691V10H7.33358V14H8.66691ZM4.66691 6V7.33333H2.00024V8.66667H4.66691V10H6.00024V6H4.66691ZM14.0002 8.66667V7.33333H7.33358V8.66667H14.0002ZM10.0002 6H11.3336V4.66667H14.0002V3.33333H11.3336V2H10.0002V6Z" />
  </AbstractSVGIcon>
);
export default ParametresSvg;
