import { FC } from 'react';
import { BaseSVGIconProps } from '../../../../shared/types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './menuItem.module.css';
export interface SwitcherItemProps {
  selectedItem: string;
  handleClick: (item: string) => void;
  label: string;
  link: string;
  icon: FC<BaseSVGIconProps>;
}
const SwitcherItemStyle = (props: SwitcherItemProps) =>
  classNames({
    [styles.switchItem]: true,
    ['bg-blue-600 text-white']: props.label === props.selectedItem,
    ['text-gray-500']: props.label !== props.selectedItem
  });
const SwitcherItem: FC<SwitcherItemProps> = (props) => {
  return (
    <Link
      to={props.link}
      className={SwitcherItemStyle(props)}
      onClick={() => props.handleClick(props.label)}
    >
      <props.icon />
      {props.label}
    </Link>
  );
};

export default SwitcherItem;
