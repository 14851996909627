import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const DashboardSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M12.6669 3.33333V4.66667H10.0002V3.33333H12.6669ZM6.00024 3.33333V7.33333H3.33358V3.33333H6.00024ZM12.6669 8.66667V12.6667H10.0002V8.66667H12.6669ZM6.00024 11.3333V12.6667H3.33358V11.3333H6.00024ZM14.0002 2H8.66691V6H14.0002V2ZM7.33358 2H2.00024V8.66667H7.33358V2ZM14.0002 7.33333H8.66691V14H14.0002V7.33333ZM7.33358 10H2.00024V14H7.33358V10Z" />
  </AbstractSVGIcon>
);
export default DashboardSvg;
