import styles from './profileCard.module.css';
import classNames from "classnames";

interface ProfileCardProps {
    imageUrl: string|undefined;
    name: string|undefined;
    email: string|undefined;
    version?: string;
    signout:()=>void;
    className?:string;
}

export const ProfileCard = (props: ProfileCardProps) => {
    const { name, email, version,signout,className=""} = props;
    const elements=[
        {
            label: 'Paramètres',
            icon: <i className=" material-icon !text-slate-500">settings</i>,
            onClick:()=>{}
        },
        {
            label: 'Se déconnecter',
            icon: <i className="material-icon !text-red-600">logout</i>,
            onClick:signout }
    ]
    const rootStyle=classNames({
        [styles.profileCard]:true,
        [className]:true
    })
    return (
        <div className={rootStyle} >
            <div className={styles.profileInfo}>
                <div className="rounded-full flex items-center justify-center size-9">
                    <img src={props.imageUrl} alt="" className="aspect-square w-full rounded-full" />
                </div>
                <div className="flex flex-col justify-center items-start ">
                    <span className={styles.profileCardTitle}>{name}</span>
                    <span className={styles.profileCardDescription}>{email}</span>
                </div>
                <div>
                </div>
            </div>
            <div className={styles.profileList}>
                {elements.map((element,index) => (
                   <div key={index} className="flex gap-3 px-2 py-2 cursor-pointer hover:bg-slate-100 active:bg-slate-200" onClick={element.onClick}>
                       {element.icon}
                       <span className={styles.elementLabel}>{element.label}</span>
                   </div>
                    ))}
            </div>
            <div className={styles.version}>
                {version}
            </div>
        </div>
    );
};


