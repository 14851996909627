import { FilterBar } from '../../components/filter-bar/FilterBar';
import { fBc, fBh, fBi, fBs } from '../../mockdata/MockDataFilterBar';
import { CardGird } from '../../components/card-grid/CardGird';
import { guichets } from '../../mockdata/MockDataOrganizations';

export const OrganisationsPage = () => {
  // const { parent, hasChilds } = useFilterBarHeader();
  // const fBh: FilterBarHeaderProps = {
  //   hasChilds: hasChilds,
  //   icon: HomeSvg,
  //   parent: parent
  // };
  return (
    <div className='flex flex-col gap-7 p-5'>
      <FilterBar
        filterBarContent={fBc}
        filterBarInput={fBi}
        filterBarHeader={fBh}
        filterBarSortBy={fBs}
      />
      <CardGird items={guichets}/>
    </div>
  );
};
