import {useForm} from "react-hook-form";
import {OnboardingStepOneRequest} from "../../../../types";
import {yupResolver} from "@hookform/resolvers/yup";
import {onboardingStepOne} from "../../../../holders/schemas";
import {NotificationCard, SimpleCard} from "../../../../../../shared/components/ui";
import {OrganisationIcon} from "../../../icons";
import {ControlledDropdown, ControlledInput} from "../../../../../../shared/components/controlled-ui";
import styles from "./steps.module.css";
import {Button} from "kls-ui";
import {ImageUploader} from "../image-uploader/ImageUploader";
import {StepsHeader} from "../layout/StepsHeader";
import {OrganisationCard} from "../cards/OrganisationCard";
import {GuichetStatus} from "../../../../../../shared/utils/enums";
import {AcademicCapIcon } from "@heroicons/react/16/solid";
import classNames from "classnames";
import {NotificationType} from "../../../../../../shared/utils/constants";

export const OrgCreationStep = () => {
    const {
        control,
    } = useForm<OnboardingStepOneRequest>({
        resolver: yupResolver(onboardingStepOne)
    });
    const rootContainer=classNames({
        [styles["onboarding-steps-css-var"]]:true
    })
    const errorMessageStyles = classNames({
        ["!my-4"]:true,
        ['notShow']:false,
        ['show transition-all duration-500']:true
    });
    const elements=[
        {label:"education", value:"education",icon:"school"},
        {label:"global", value:"education",icon:"water"},
        {label:"gift", value:"education",icon:"hiking"},
    ]
    return (
        <SimpleCard className={rootContainer}>
            <StepsHeader
                icon={<OrganisationIcon className="size-12"/>}
                title={"Créez un nouveau guichet"}
                description={"Veuillez saisir les informations de votre guichet pour la créer"}
            />
            <NotificationCard
                type={NotificationType.ERROR}
                className={errorMessageStyles}>
             erreur servenu
            </NotificationCard>
            <div className="flex flex-col gap-3">
                <ImageUploader/>
                <div className="py-3">
                    <ControlledInput
                        size={'sm'}
                        label={'Nom de guichet'}
                        control={control}
                        name={'firstName'}
                        args={{ placeholder: "Entrez l’intitulé de votre guichet" }}
                        error={""}
                    />
                    <ControlledDropdown
                        control={control}
                        label={"Industrie"}
                        error={""}    name={"question_1"}
                        elementSize={32}
                        elements={elements}
                        size={"sm"} listzIndex={20} args={{placeholder:"Sélectionnez votre choix"}}
                    />

                    <ControlledInput
                        size={'sm'}
                        label={"Nom de domaine"}
                        control={control}
                        name={'userName'}
                        args={{ placeholder: "Entrez un acronyme sans espaces ni caractères spécieux" }}
                        error={""}
                        domainTextColor="var(--mapping-slate-800)"
                        domainText="@karaz.org"
                    />
                </div>
                <div className={styles.orgCreationStepSimilarOrgContainer}>
                    <div className={styles.orgCreationStepSimilarOrgTitle}>
                        <i className="material-icon !text-md ">flare</i>
                        <div>Guichets similaires :</div>
                    </div>

                    <div className="flex flex-col gap-2 py-3 px-2">
                         <OrganisationCard key={"k"} title={"Université Mohammed VI Polytechnique"} icon={<AcademicCapIcon className={"size-4 text-blue-600"}/>} state={GuichetStatus.VERIFIED} onCheck={()=>{}}/>
                        <OrganisationCard key={"k"} title={"Université Mohammed VI Polytechnique"} icon={<AcademicCapIcon className={"size-4 text-emerald-600"}/>} state={GuichetStatus.IN_PROGRESS} onCheck={()=>{}}/>
                    </div>
                </div>
            </div>
            <div className={styles.actionButton}>
                <Button label="valider" color="primary" size="sm" styling="solid" args={{}}/>
            </div>
        </SimpleCard>
    )

}
