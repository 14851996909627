import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const MonitoringSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M14.6668 4.7833L13.7268 3.8433L11.8268 5.9833C10.4535 4.43663 8.5535 3.5033 6.40683 3.5033C4.48016 3.5033 2.7135 4.27663 1.3335 5.5033L2.28016 6.44996C3.4135 5.45663 4.84683 4.83663 6.40683 4.83663C8.2335 4.83663 9.80016 5.67663 10.9202 6.99663L9.00016 9.15663L6.3335 6.48996L1.3335 11.4966L2.3335 12.4966L6.3335 8.48996L9.00016 11.1566L11.7002 8.1233C12.2002 9.0233 12.5335 10.0566 12.6602 11.1566H14.0002C13.8535 9.6233 13.3668 8.22996 12.6402 7.0633L14.6668 4.7833Z" />
  </AbstractSVGIcon>
);
export default MonitoringSvg;
